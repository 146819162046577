import React from "react";

const TrustpilotReviewWidget = () => {
  return (
    <div className="trustpilot-review-widget">
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="67dbe74ef68b69ff84c477aa"
        data-style-height="52px"
        data-style-width="100%"
      >
        <a
          href="https://www.trustpilot.com/review/cypherforensics.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
      {/* End TrustBox widget */}
    </div>
  );
};

export default TrustpilotReviewWidget;
