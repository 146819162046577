import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";

const API = () => {
  const [value, setValue] = React.useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="mainlandingpage apipadding">
        <LandingHeader />
        <div className="apiwrapperbox">
          <h2 style={{ color: "wheat" }}>
            Boost your company’s security with our reliable scam detection APIs.
            Protect your customers and elevate your reputation.
          </h2>
          <div className="apiwrapper">
            {" "}
            <Box
              sx={{ maxWidth: { xs: 320, sm: 480 } }}
              className="apitabshead"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Data Feed" style={{ color: "wheat" }} />

                <Tab
                  label="Brand Monitoring Service"
                  style={{ color: "wheat" }}
                />

                <Tab
                  label="Online Brand Protection"
                  style={{ color: "wheat" }}
                />
              </Tabs>
            </Box>
            <Box
              sx={{ maxWidth: { xs: 320, sm: 480 } }}
              className="apitabsbody"
            >
              {" "}
              <TabPanel value={value} index={0}>
                <article style={{ color: "white" }}>
                  <h5>Access to Cypher Forensics Dataset</h5>
                  <p>
                    For antivirus providers, cybersecurity companies, and
                    malware detection organizations, we offer seamless access to
                    the Cypher Forensics comprehensive dataset via a live feed.
                    An update file is generated every 30 minutes, reflecting the
                    latest changes, which are then compiled into hourly and
                    daily files.
                  </p>

                  <h6>Each file comprises:</h6>
                  <ul>
                    <li>
                      Newly scanned and updated websites from the past 24 hours
                    </li>
                    <li>An MD5 checksum for authenticity</li>
                    <li>Domain name information</li>
                    <li>
                      Trust score ranging from 0 to 100, along with explanations
                      for each score
                    </li>
                    <li>Full WHOIS data</li>
                    <li>Consolidated consumer reviews</li>
                    <li>Comments from Facebook</li>
                    <li>Technologies utilized on the domains</li>
                  </ul>

                  <p>
                    The data is formatted in JSON. Pricing is determined by the
                    specific usage of the data feed.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <article>
                  <h5>Brand Monitoring Service</h5>
                  <p>
                    We monitor all advertising and sales activities on Facebook
                    and Instagram related to your brand, identifying fake
                    webshops. Additionally, we can swiftly and easily facilitate
                    the removal of ads on these platforms for you.
                  </p>

                  <p>
                    Our service also enables you to track sales offers and
                    sellers globally on Facebook Marketplace using various
                    search parameters, including keyword and image searches. You
                    can take action against specific infringements or entire
                    accounts as needed.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <article>
                  <h5>Online Brand Protection</h5>
                  <p>
                    Benefit from our comprehensive app monitoring service for
                    your brand:
                  </p>

                  <ul>
                    <li>Monitoring of over 30 app stores worldwide</li>
                    <li>
                      Automated tracking of various app types based on keywords
                    </li>
                    <li>
                      Evaluation of unauthorized developers and contractors
                      acting without your permission
                    </li>
                    <li>
                      Identification of replicated, phishing versions of
                      official apps
                    </li>
                    <li>
                      Monitoring the latest versions of your apps to identify
                      security vulnerabilities and ensure a consistent corporate
                      identity
                    </li>
                    <li>
                      Quick and easy takedowns or comprehensive enforcement
                      programs
                    </li>
                  </ul>
                </article>
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default API;
