import React, { useState } from "react";
import "../landingstyles.css";
import Input from "@mui/material/Input";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import LandingFooter from "./LandingFooter";
import { login, resendLink } from "../../services/auth";
import Loader2 from "../../dashcomponents/pages/Loaders/Loader2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleLogin = async () => {
    const { email, password } = formData;

    if (!email && !password) {
      setError("Email and password is required");
    }
    setLoading(true);
    try {
      const response = await login(email, password);

      if (response.data.redirect) {
        await resendLink(email);

        navigate("/email-verification");
        return;
      }

      localStorage.setItem("token", response.data);
      navigate("/account/home");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setError({ message: ex.response.data });
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="mainlandingpageb">
        <div
          style={{
            display: "flex",
            minWidth: "100%",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader2 />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mainlandingpage">
        <div className="loginwrapper">
          <Link className="arrowback" to="/">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              color="wheat"
              style={{ fontSize: "1.5rem" }}
            />{" "}
            {""} back
          </Link>
          <div className="loginbox">
            <div className="loginlockicon">
              <LockOutlinedIcon
                style={{ backgroundColor: "black", borderRadius: "50%" }}
                color="white"
              />
            </div>
            <h3>Sign in</h3>
            <p className="loginwelcome">
              Welcome back, please sign in to continue
            </p>
            <div className="loginforms">
              <FormGroup>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "0.5rem",
                      borderRadius: "1%",
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleChange}
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "0.5rem",
                      borderRadius: "1%",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error.message && (
                    <p style={{ color: "red" }}>{error.message}</p>
                  )}
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="white"
                      style={{ color: "white", marginLeft: "0.4rem" }}
                    />
                  }
                  label="Remember me"
                />
              </FormGroup>
              <button
                className="loginsubmitbtn"
                type="button"
                onClick={handleLogin}
              >
                <Typography> SIGN IN</Typography>
              </button>
              <div className="loginextras">
                <Link className="linktryout" to="/reset-password">
                  Forgot password?
                </Link>
                <Link className="linktryout" to="/register">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Login;
