import React, { useEffect } from "react";
import "./landingstyles.css";
import LandingHeader from "./LandingComponents/LandingHeader";
import LandingProductCard from "./LandingComponents/LandingProductCard";
import LandingMain from "./LandingComponents/LandingMain";
import LandingFooter from "./LandingComponents/LandingFooter";

const LandingPage = () => {
  useEffect(() => {
    // Dynamically load the Trustpilot script
    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="landingpagewrapper">
      <div className="cashbackwarrant">
        <article> 30 days Cash back guarantee</article>{" "}
        <article> Get your cash back after 30 days if you request</article>
      </div>
      <div className="mainlandingpage">
        <LandingHeader />
        <LandingProductCard />
      </div>
      <div className="sectionA">
        <LandingMain />
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingPage;
